import { ChessGame, ChessGamePreview } from "@/types";
import { uploadGameImages } from "../server/buildUploadPayload";
import axios from "axios";
import { OcrMultipleUrlReturnType } from "../validators/ocr-url-schema";

const resubmitGame = async (game: ChessGamePreview | ChessGame) => {
  try {
    // Fetch the concatenated game image
    const response = await fetch(game.images.original);
    const blob = await response.blob();

    // Convert blob to File object with 'original.png' filename
    const imageFile = new File([blob], "original.png", {
      type: blob.type,
    });

    // Generate a random ID
    const randomId = Math.random().toString(36).substring(2, 15);

    // Create FileWithId type objects expected by upload function
    // Same file twice so one becomes compositeUpload and the other separateUpload in the API route before sending to rabbitMQ
    const imageData = {
      file: imageFile,
      id: randomId,
    };

    const fakeConcatenatedImageData = {
      file: imageFile,
      id: randomId,
      isOriginal: true,
    };

    const resp = await uploadGameImages([imageData, fakeConcatenatedImageData]);

    // Send image url to resubmit API route
    const { data } = await axios.post<OcrMultipleUrlReturnType>(
      `/api/game/${game.id}/resubmit`,
      {
        imageUrls: [...resp.urls],
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return data;
  } catch (error) {
    console.error({ error });
    throw error;
  }
};

export default resubmitGame;
