import { create, StoreApi, UseBoundStore } from "zustand";
import Chess from "chess.js";
import { CollectionPreview } from "@/types/FullCollectionType";
import { IdentifiedColumnDef } from "@tanstack/react-table";
import { SelectedGamePreview } from "@/app/services";

interface ChessState {
  game: typeof Chess.Chess;
  setGame: (game: typeof Chess.Chess) => void;
}

export const useChessStore = create<ChessState>((set) => ({
  game: new Chess(),
  setGame: (game) => set({ game }),
}));

export interface CollectionPreviewState {
  collections: CollectionPreview[];
  setCollections: (collections: CollectionPreview[]) => void;
  addToCollections: (newCollection: CollectionPreview) => void;
  editCollection: ({
    collectionId,
    collection,
  }: {
    collectionId: string;
    collection: CollectionPreview;
  }) => void;
  deleteCollection: (collectionId: string) => void;
}

export const useCollectionStore = create<CollectionPreviewState>((set) => ({
  collections: [],
  setCollections: (collections: CollectionPreview[]) => set({ collections }),
  addToCollections: (newCollection: CollectionPreview) => {
    set((state) => {
      return {
        collections: [...state.collections, newCollection],
      };
    });
  },
  editCollection: ({
    collectionId,
    collection,
  }: {
    collectionId: string;
    collection: CollectionPreview;
  }) => {
    set((state) => {
      return {
        collections: state.collections.map((initialCollection) => {
          if (initialCollection.id === collectionId) return collection;
          return initialCollection;
        }),
      };
    });
  },
  deleteCollection: (collectionId: string) => {
    set((state) => {
      return {
        collections: state.collections.filter(
          (collection) => collection.id !== collectionId,
        ),
      };
    });
  },
}));

export interface SelectedGamePreviewState {
  items: SelectedGamePreview[];
  setItems: (items: SelectedGamePreview[]) => void;
}

export const useSelectedGameStore = create<SelectedGamePreviewState>((set) => ({
  items: [],
  setItems: (items) => set({ items }),
}));

export interface SelectedCollection extends CollectionPreview {
  selected: boolean;
}

interface SelectedCollectionState {
  items: SelectedCollection[];
  setItems: (items: SelectedCollection[]) => void;
}

export const useSelectedCollectionStore = create<SelectedCollectionState>(
  (set) => ({
    items: [],
    setItems: (items) => set({ items }),
  }),
);
