import {
  OcrMultipleUrlReturnType,
  OcrMultipleUrlSchemaType,
} from "../validators/ocr-url-schema";
import axios from "axios";
import {
  BlobWithId,
  FileWithId,
  PossibleFlowInputTypes,
} from "@/types/imageUploadMinioType";
import ClientStorageService from "@/app/services/client/storage.client-service";
import { TChessAiUploadFormSchema } from "../validators/chess-ai-upload-form-schema";
import { IMAGE_UPLOAD_FOLDER_NAME } from "../constants";

interface ImageProcessingOptions {
  maxHeight?: number;
  concatenationQuality?: number;
  thumbnailQuality?: number;
}

export const sendToGameUploadAPI = async (
  payload: OcrMultipleUrlSchemaType,
) => {
  const { data } = await axios.post<OcrMultipleUrlReturnType>(
    "/api/game/upload_src",
    {
      ...payload,
      separate: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return data;
};

const convertPotentialBlobToFile = (
  obj: FileWithId | BlobWithId,
  index: number,
) => {
  if ("blob" in obj) {
    return {
      file: new File([obj.blob], `blob_${index}`, { type: "image/png" }),
      id: obj.id,
    };
  } else return obj;
};

const resolveObjectsToFiles = (
  objects: PossibleFlowInputTypes,
): Array<FileWithId> => {
  let resolvedFiles: Array<FileWithId> = [];
  if (!Array.isArray(objects)) {
    resolvedFiles.push(convertPotentialBlobToFile(objects, 0));
  } else {
    resolvedFiles = objects.map((obj, index) =>
      convertPotentialBlobToFile(obj, index),
    );
  }
  return resolvedFiles;
};

/**
 * Uploads game images to s3/minio using the correct subfolder, pre generated gameId.
 * @param images Images to upload with ids to be able to know which image is which
 * @returns Array of relative urls of the images uploaded or an error object
 */
export async function uploadGameImages(images: FileWithId[] | FileWithId) {
  const blobUploadService = new ClientStorageService();
  const resp = await blobUploadService.postImagesToBlobStorage({
    imagesParam: images,
    subfolderPath: IMAGE_UPLOAD_FOLDER_NAME,
    shouldCreateUuidSubfolder: true,
  });

  return {
    urls: resp.urls,
    gameUuid: resp.uuidSubfolder,
  } as { urls: string[]; gameUuid: string };
}

/**
 * Full upload flow. Takes in memory files, uploads to S3/blob storage, then sends the image urls to the game upload API alongside the optional game details.
 * @param inMemoryFiles array of blob or files
 * @param optionalGameData optional game data to send to the game upload API: tournaments, comments etc
 * @returns
 */
export async function uploadInMemoryFilesToOCR(
  inMemoryFiles: PossibleFlowInputTypes,
  optionalGameData?: TChessAiUploadFormSchema,
) {
  const resolvedFiles = resolveObjectsToFiles(inMemoryFiles); //convert potential blobs to files

  const imagesWithConcatenation = await processImagesForOCR(resolvedFiles);

  const resp = await uploadGameImages(imagesWithConcatenation);

  //TODO: should clean-up the uploaded images if there's an error
  const uploadAPIresp = await sendToGameUploadAPI({
    image_urls: resp.urls,
    ...optionalGameData,
  });
  return uploadAPIresp;
}

async function processImagesForOCR(
  resolvedFiles: any[],
  options: ImageProcessingOptions = {},
) {
  // Default options
  const { maxHeight = 1440, thumbnailQuality = 0.3 } = options;

  // Convert file blobs to image elements with resizing
  const processedImages = await Promise.all(
    resolvedFiles.map(async (fileObj) => {
      return new Promise<{
        img: HTMLImageElement;
        resizedBlob: Blob;
        scaledWidth: number;
      }>((resolve, reject) => {
        const img = new Image();
        img.onload = async () => {
          // Resize image maintaining aspect ratio
          const canvas = document.createElement("canvas");
          const scaleFactor = maxHeight / img.height;

          canvas.height = maxHeight;
          canvas.width = img.width * scaleFactor;

          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject(new Error("Could not create 2D context"));
            return;
          }
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert to blob with quality control
          const resizedBlob = await new Promise<Blob>((blobResolve) => {
            canvas.toBlob((blob) => {
              if (blob) {
                blobResolve(blob);
              } else {
                reject(new Error("Failed to create blob"));
              }
            }, "image/png");
          });

          resolve({ img, resizedBlob, scaledWidth: canvas.width });
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(fileObj.file);
      });
    }),
  );

  // Calculate total width after resizing
  const totalWidth = processedImages.reduce(
    (sum, { scaledWidth }) => sum + scaledWidth,
    0,
  );

  // Create composite image canvas
  const compositeCanvas = document.createElement("canvas");
  compositeCanvas.width = totalWidth;
  compositeCanvas.height = maxHeight;
  const ctx = compositeCanvas.getContext("2d");

  if (!ctx) throw new Error("Could not create 2d canvas context!");

  // Draw resized images horizontally
  let currentX = 0;
  processedImages.forEach(({ img, scaledWidth }) => {
    ctx.drawImage(img, currentX, 0, scaledWidth, maxHeight);
    currentX += scaledWidth;
  });

  // Convert composite to blob
  const compositeBlob = await new Promise<Blob>((resolve, reject) => {
    compositeCanvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Failed to create composite blob"));
      }
    }, "image/png");
  });

  // Create WebP Thumbnail blob
  const thumbnailBlob = await new Promise<Blob>((resolve, reject) => {
    compositeCanvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Failed to create thumbnail blob"));
        }
      },
      "image/webp",
      thumbnailQuality,
    );
  });

  const compositeFile = new File([compositeBlob], "original.png", {
    type: "image/png",
  });

  const thumbnailFile = new File([thumbnailBlob], "original.webp", {
    type: "image/webp",
  });

  return [
    ...resolvedFiles,
    { file: compositeFile, id: crypto.randomUUID(), isOriginal: true },
    { file: thumbnailFile, id: crypto.randomUUID(), isOriginal: true },
  ];
}
