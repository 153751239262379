import { cn } from "@/lib/utils";
import clsx from "clsx";
import { getPlanCss, shouldShowTrialingDetails } from "./utils";
import {
  extractPlanValue,
  findDefaultPriceOfPlan,
  findOriginalPriceOfPlan,
  PossiblePlansNoFree,
} from "@/lib/shared/pricing/utils";
import { PlanCardType } from "../types/types";

type FeatureType = {
  text: string;
  footnote?: string;
  negative?: boolean;
};

type PriceCardProps = {
  wrapperForPlanBannerText?: string;
  shouldShowBanner?: boolean;
  planTitle: string;
  tagline: string;
  features: FeatureType[];
  footer: React.ReactNode;
  openEditModal?: () => void;
  planCss: ReturnType<typeof getPlanCss>;
  sbPlan: PossiblePlansNoFree;
  activeSbPlan: PlanCardType["activeSubscriptionPlan"];
};

const PriceCardDesign = ({
  isAdmin,
  wrapperForPlanBannerText,
  planTitle,
  tagline,
  features,
  footer,
  shouldShowBanner = true,
  openEditModal,
  planCss,
  sbPlan, //sb plan corresponding to the pricecard
  activeSbPlan,
}: PriceCardProps & { isAdmin: boolean; isComingSoon: boolean }) => {
  const textStyle = planCss.textStyle;
  const renderFeatures = (features: FeatureType[]) => {
    const renderFeature = (feature: FeatureType) => {
      return (
        <div className="flex justify-start self-stretch text-slate-600 text-base font-normal leading-normal">
          {feature.text}
        </div>
      );
    };

    return (
      <div className="self-stretch h-36 mt-auto pb-6 pt-6 border-t border-slate-300 flex-col justify-start items-start gap-2 flex">
        {features.map((feature) => renderFeature(feature))}
      </div>
    );
  };

  const shouldShowTrial = shouldShowTrialingDetails(
    activeSbPlan,
    Object.keys(sbPlan)[0] as keyof PossiblePlansNoFree,
  );

  const renderPrice = () => {
    const sbPlanValue = extractPlanValue(sbPlan);
    const defaultPrice = findDefaultPriceOfPlan(sbPlan);
    const originalPrice = findOriginalPriceOfPlan(sbPlan);

    if (!defaultPrice || !originalPrice) return <></>;

    return (
      <div
        className={clsx(
          "self-stretch flex-col justify-start items-start gap-2 flex min-h-[201.5px]",
        )}
      >
        {shouldShowTrial && (
          <>
            <div className="flex justify-start self-stretch text-slate-800 text-4xl font-bold leading-10">
              Free for 1 month
            </div>
            <div className="flex justify-start self-stretch text-slate-600 text-sm leading-tight">
              then
            </div>
          </>
        )}
        {originalPrice.amount > defaultPrice.amount && (
          <div className="flex justify-start self-stretch text-slate-600 text-2xl line-through leading-loose">
            ${originalPrice.amount / 100}
          </div>
        )}
        <div
          className={`flex justify-start self-stretch ${textStyle} text-4xl font-bold leading-10`}
        >
          ${defaultPrice.amount / 100}
        </div>
        <div className="flex justify-start self-stretch text-slate-800 text-base font-bold leading-normal">
          {sbPlanValue.submissions} image submissions / month *
        </div>
        <div className="flex justify-start self-stretch text-slate-800 text-base font-bold leading-normal">
          {sbPlanValue.quotaImportedGames} imported PGN games **
        </div>
      </div>
    );
  };

  const isPlanActive = activeSbPlan.planType === Object.keys(sbPlan)[0];

  return (
    <div className="relative w-full col-span-3 lg:col-span-1 px-6 py-8 bg-white rounded-2xl shadow-lg border-2 border-slate-200 flex-col justify-center items-center gap-16 inline-flex">
      {isAdmin && (
        <div className="flex absolute right-0 top-0">
          <button
            onClick={openEditModal}
            className={cn("p-2 text-gray-500 hover:text-gray-800")}
          >
            Edit
          </button>
        </div>
      )}
      <div
        className={clsx(
          "self-stretch flex-col justify-start items-start gap-12 flex",
        )}
      >
        <div className="self-stretch h-16 flex-col justify-start items-center gap-2 flex">
          <div className="self-stretch justify-between items-start inline-flex">
            <div
              className={`${planCss.textStyle} text-3xl font-bold  leading-9`}
            >
              {planTitle}
            </div>

            {shouldShowBanner && (
              <div
                className={
                  isPlanActive ? planCss.bannerActive : planCss.bannerUpgrade
                }
              >
                <div className="text-sm font-semibold  leading-tight">
                  {wrapperForPlanBannerText}
                </div>
              </div>
            )}
          </div>
          <div className="flex justfiy-start self-stretch text-slate-600 text-sm font-normal  leading-tight">
            {tagline}
          </div>
        </div>
        <div className="self-stretch h-max flex-col justify-start items-start gap-2 flex">
          {renderPrice()}
        </div>
      </div>
      {renderFeatures(features)}
      <div className="self-stretch h-[42px] py-3 rounded-lg justify-center items-center gap-1 inline-flex">
        {footer}
      </div>
    </div>
  );
};

export default PriceCardDesign;
