import {
  FileWithId,
  GetPresignedReqBodyType,
  PresignedResponse,
  SingleImageMetadata,
} from "@/types/imageUploadMinioType";
import axios, { AxiosError } from "axios";

const fetchReqToBlobStorage = async (presignedUrl: string, file: File) => {
  const uploadResponse = await fetch(presignedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type,
    },
    mode: "cors",
  });
  return uploadResponse;
};

class ClientStorageService {
  constructor() {}

  /**
   * Gets a map of presigned URLs mapped to their corresponding image, to be used for uploading images to s3/minio
   * @param body
   * @returns
   */
  async getPresignedUrlMappings(body: GetPresignedReqBodyType) {
    const { data: imageMetadataWithPresignedUrlMap } =
      await axios.post<PresignedResponse>(
        "/api/blobStorage/presigned_url/multiple",
        body,
      );
    return imageMetadataWithPresignedUrlMap;
  }
  /**
   * Uploads an array of images to s3/minio
   * @param imagesParam Array of images to upload
   * @param subfolderPath MUST NOT HAVE A '/' PREFIX FOR S3 API COMPATIBILITY PURPOSES. Otherwise, the subfolder found in s3/minio to upload these images to. Must be only the subfolder and not the name of the file itself, the name is generated serverside.
   * @param shouldCreateUuidSubfolder If true, the images will be uploaded at /subfolderPath/uuidv4()/image_name
   * @returns An array of responses from the server
   */
  async postImagesToBlobStorage({
    imagesParam,
    subfolderPath,
    shouldCreateUuidSubfolder = false,
  }: {
    imagesParam: FileWithId[] | FileWithId;
    subfolderPath?: string; //Could be problematic if meddled with clientside. Can exclude this and default to /uploads if this method won't be a general method to upload to S3.
    shouldCreateUuidSubfolder?: boolean;
  }) {
    let imagesWithId = imagesParam;

    if (!Array.isArray(imagesWithId)) {
      imagesWithId = [imagesWithId];
    }

    const imageMetadata: SingleImageMetadata[] = imagesWithId.map((image) => ({
      name: image.file.name,
      type: image.file.type,
      id: image.id,
      isOriginal: image.isOriginal,
    }));
    console.log("imageMetadata", imageMetadata);
    //fetches presigned urls for each image
    const response = await this.getPresignedUrlMappings({
      imageMetadata,
      expiryMinutes: 60,
      subfolderPath: subfolderPath || "",
      shouldCreateUuidSubfolder,
    });

    console.log("response", response);
    const promises = response.images.map(async (img) => {
      const fileToSend = imagesWithId.find((file) => file.id === img.id);
      if (!fileToSend)
        throw new Error(`File could not be uploaded ${img.name}`);

      await fetchReqToBlobStorage(img.presignedUrl, fileToSend.file);

      return img.urlUploadedTo;
    });

    await Promise.all(promises);

    const uploadedImagesUrlArr = response.images.map(
      (img) => img.urlUploadedTo,
    );

    return {
      urls: uploadedImagesUrlArr,
      uuidSubfolder: response.subfolderGenerated,
    };
  }
}

export default ClientStorageService;
