import { useState } from "react";
import Image, { ImageProps } from "next/image";
import { StaticImport } from "next/dist/shared/lib/get-img-props";

export interface SafeImageProps extends Omit<ImageProps, "src"> {
  src: StaticImport | string | null;
  fallbackUrl: StaticImport | string;
}

export function SafeImage(props: SafeImageProps) {
  const { src: imgUrl, fallbackUrl, ...rest } = props;
  const [failed, setFailed] = useState(false);
  const src = !!imgUrl && !failed ? imgUrl : fallbackUrl;

  return <Image src={src} onError={() => setFailed(true)} {...rest} />;
}
