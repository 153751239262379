import { GameSource } from "@/prisma/schema/mysql";
import { z, ZodObject } from "zod";
import { INFINITE_SCROLL_PAGINATION_RESULTS } from "../constants";
import Pagination from "../../app/(dashboard)/account/feedback/components/Pagination";
/*
  !!!Axios converts query parameters to strings.
  !!!If you want to extend the schemas, provide fallback mechanisms for string parsing into your desired type
*/

export const tryParseToNumber = (value: any, defaultVal: number): number => {
  if (typeof value !== "number" && typeof value !== "string") return defaultVal;
  if (typeof value === "number") return value;
  const parsed = parseInt(value, 10);
  return !isNaN(parsed) ? parsed : defaultVal;
};

function tryParseToBoolean(input: any, defaultValue: boolean): boolean {
  if (typeof input === "string") {
    const trimmedValue = input.trim().toLowerCase();
    if (trimmedValue === "true") {
      return true;
    } else if (trimmedValue === "false") {
      return false;
    }
  }
  return defaultValue;
}

export const possibleOrderByValues = [
  "gameScore/ocrAccuracy",
  "gameScore/fischerScoreTotal",
  "gameScore/total",
  "name",
  "createdAt",
] as const;

export const possibleOrderByValuesCollections = [
  "name",
  "createdAt",
  "updatedAt",
  "date",
] as const;

export const filterSchemaForSearchbar = z.object({
  player: z
    .string()
    .nullable()
    .optional()
    .catch((e) => null),
  pgn: z
    .string()
    .nullable()
    .optional()
    .catch((e) => null),
  username: z
    .string()
    .nullable()
    .optional()
    .catch((e) => null),
  opening: z
    .string()
    .nullable()
    .optional()
    .catch((e) => null),
  sharing: z
    .enum(["ENABLED", "DISABLED"])
    .nullable()
    .optional()
    .catch((e) => null),
});

export type filterSchemaForSearchbarType = z.infer<
  typeof filterSchemaForSearchbar
>;

export const paginationSchema = z.object({
  page: z
    .number()
    .default(1)
    .catch((e) => tryParseToNumber(e.input, 1)),
  pageSize: z
    .number()
    .default(INFINITE_SCROLL_PAGINATION_RESULTS)
    .catch((e) =>
      tryParseToNumber(e.input, INFINITE_SCROLL_PAGINATION_RESULTS),
    ),
});

export type paginationSchemaType = z.infer<typeof filterSchemaCollections>;

export const filterSchemaBase = filterSchemaForSearchbar
  .extend({
    page: z
      .number()
      .default(1)
      .catch((e) => tryParseToNumber(e.input, 1)),
    pageSize: z
      .number()
      .default(INFINITE_SCROLL_PAGINATION_RESULTS)
      .catch((e) =>
        tryParseToNumber(e.input, INFINITE_SCROLL_PAGINATION_RESULTS),
      ),
    order: z
      .enum(["asc", "desc"])
      .default("asc")
      .optional()
      .catch((e) => "desc"),
    gridView: z
      .boolean()
      .optional()
      .nullable()
      .catch((e) => tryParseToBoolean(e.input, false)),
    uId: z
      .string()
      .nullable()
      .optional()
      .catch((e) => null),
    restOfSearch: z
      .string()
      .nullable()
      .optional()
      .catch((e) => null),
    tId: z
      .string()
      .nullable()
      .optional()
      .catch((e) => null),
    gameSource: z
      .enum([
        GameSource.IMAGE,
        GameSource.PGN,
        GameSource.PGN_CHESS_COM,
        GameSource.PGN_LICHESS_ORG,
        "all",
      ])
      .nullable()
      .optional()
      .catch((e) => "all"),
    gameOrigin: z
      .enum(["shared", "personal", "all"])
      .nullable()
      .optional()
      .catch((e) => "all"),
    favorite: z
      .string()
      .nullable()
      .optional()
      .catch((e) => null),
    gameStatus: z
      .enum(["FAILED", "PROCESSING", "SUCCESS", "COMPLETED", "NOT_COMPLETED"])
      .nullable()
      .optional()
      .catch((e) => null),
  })
  .passthrough();

export const filterSchema = filterSchemaBase.extend({
  orderBy: z
    .enum(possibleOrderByValues)
    .default("createdAt" as "createdAt")
    .optional()
    .catch((e) => "createdAt"),
  createdAtBefore: z
    .string()
    .datetime()
    .nullable()
    .optional()
    .catch(() => null),
  createdAtAfter: z
    .string()
    .datetime()
    .nullable()
    .optional()
    .catch(() => null),
});

export const filterSchemaCollections = filterSchemaBase.extend({
  orderBy: z
    .enum(possibleOrderByValuesCollections)
    .default("createdAt" as "createdAt")
    .optional()
    .catch((e) => "createdAt"),
  name: z
    .string()
    .default("")
    .optional()
    .catch((e) => "name"),
});

export type filterSchemaTypeCollections = z.infer<
  typeof filterSchemaCollections
>;

const createNullObjectFromSchema = <T extends ZodObject<any, any, any>>(
  schema: T,
): z.output<typeof filterSchema> => {
  const shape = schema.shape;
  const nullObject = {} as any;

  for (const key in shape) {
    if (shape.hasOwnProperty(key)) {
      nullObject[key] = null;
    }
  }

  return nullObject;
};

export type filterSchemaType = z.infer<typeof filterSchema>;

/**
 * Parses a single query param using a custom zod schema.
 * !!!It counts on the fact that the schema has .catch() blocks for every field!!!
 * @param schema The zod schema to parse upon
 * @param fieldName The field name from the schema to parse
 * @param value The value to parse
 * @returns The parsed value. If the value is invalid, it will return the default value from the schema.
 */
export const parseParam = <T extends ZodObject<any, any, any>>(
  schema: T,
  fieldName: keyof T["shape"],
  value: any,
) => {
  const fieldSchema = schema.shape[fieldName];

  if (!fieldSchema) return undefined;

  try {
    const parsedValue = fieldSchema.parse(value);
    return parsedValue;
  } catch (e) {
    //it'll never reach this part if used correctly with .catch() schemas.
    return undefined;
  }
};

/**
 * Takes the query params and returns a schema object with the parsed values. If a value is not provided, it will use the default value from the schema.
 * !!!It counts on the fact that the schema has .catch() blocks for every field!!!
 * @param schema The Zod schema object defining the structure and defaults.
 * @param queryParams An object containing the query params and their values, e.g. { page: '1', limit: '10' } parsed from req.nextUrl.searchParams
 * @returns The parsed schema object
 */
export const parseAllParams = <T extends ZodObject<any, any, any>>(
  schema: T,
  queryParams: { [key: string]: any },
): z.infer<T> => {
  const dict: any = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    dict[key] = parseParam(schema, key as keyof typeof schema.shape, value);
  });

  return dict as z.infer<T>;
};

export const initializeParams = <T extends ZodObject<any, any, any>>(
  schema: T,
) => {
  const paramsIfEmpty = schema.parse(createNullObjectFromSchema(schema));
  const dict: any = paramsIfEmpty;
  return dict as z.infer<T>;
};
