import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/app/(dashboard)/pricing/components/PricingTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/web/next13-chess/components/landing/Faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/landing/HowItWorks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SampleGames"] */ "/web/next13-chess/components/landing/SampleGames.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useConfig"] */ "/web/next13-chess/components/providers/config-provider.tsx");
;
import(/* webpackMode: "eager" */ "/web/next13-chess/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/web/next13-chess/node_modules/next/dist/client/link.js");
