"use client";

import { Dispatch, memo, useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { ClientUser } from "@/types/ClientUser";
import {
  ChessGame,
  ChessGamePreview,
  PrivacyIcon,
  privacyOptions,
} from "@/types/Move";
import { Privacy } from "@/prisma/schema/mysql";
import axios from "axios";
import { cn, copyToClipboard, isAdminUser } from "@/lib/utils";
import { Button, Checkbox } from "@/components/ui";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

interface PrivacyActionProps {
  fragment?: string;
  game: ChessGamePreview | ChessGame;
  setOcrResponse?: Dispatch<React.SetStateAction<ChessGame>>;
  user: ClientUser | null;
  className?: string;
}

function PrivacyAction({
  game,
  user,
  fragment = "/api/game",
  setOcrResponse,
  className,
}: Readonly<PrivacyActionProps>) {
  const [privacy, setPrivacy] = useState<Privacy>(game.privacy ?? "PRIVATE");

  useEffect(() => {
    setPrivacy(game.privacy as Privacy);
  }, [game.privacy]);

  const canEdit = () => {
    if (!user) {
      return false;
    }

    if (isAdminUser(user) || game.user?.id === user.id) {
      return true;
    }

    return false;
  };

  const handleChange = async (e: any) => {
    e.preventDefault();
    if (!canEdit()) return;
    try {
      const newPrivacy: Privacy =
        privacy === "PRIVATE" ? "UNLISTED" : "PRIVATE";
      setPrivacy(newPrivacy);
      await axios.patch(`${fragment}/${game.id}`, {
        privacy: newPrivacy,
      });
      // if (setOcrResponse) {
      //   setOcrResponse({
      //     ...(game as ChessGame),
      //     privacy: value as Privacy,
      //   });
      // }
    } catch (error) {
      const message = "Something went wrong;";
      toast({
        variant: "destructive",
        description: message,
        duration: 2000,
      });
    }
  };

  const handleCopyLink = (e: any) => {
    e.preventDefault();
    const { protocol, host, pathname } = window.location;
    console.log(protocol, host, pathname);
    const url = new URL(`${protocol}//${host}/game/${game.id}`).toString();
    copyToClipboard(url);
    toast({
      description: "Link copied to clipboard.",
    });
  };

  return (
    <div className="flex gap-3">
      {privacy === "UNLISTED" && (
        <div
          className="bg-slate-100 flex items-center p-2 gap-3 rounded-md"
          onClick={handleCopyLink}
        >
          <p className="font-semibold text-slate-800">Copy link</p>
          <DocumentDuplicateIcon className="w-[24px] h-[24px] fill-brand-secondary" />
        </div>
      )}
      <div
        className="bg-slate-100 flex items-center p-2 gap-3 rounded-md"
        onClick={handleChange}
      >
        <p className="font-semibold text-slate-800">Sharing</p>
        <Checkbox
          className="w-[24px] h-[24px] data-[state=checked]:bg-brand-secondary data-[state=checked]:text-white border-brand-secondary"
          checked={privacy === "UNLISTED"}
        />
      </div>
    </div>
  );
}

export const PrivacyActions = memo(PrivacyAction);
