// This is added for performance reasons
//@ts-nocheck
import { writeFile } from "fs";
import * as openings from "./eco.json";
const map = new Map<string, string>();
const openingsSet = new Set<string>();

for (let i = 0; ; i++) {
  let obj = openings[i];
  if (!obj) break;
  map.set(obj.moves, obj.name);
}

export function getVariationRoot(x) {
  return x.split(":")[0];
}

export function parseVariations() {
  for (let i = 0; ; i++) {
    let obj = openings[i];
    if (!obj) break;
    const sp = obj.name.split(":");
    if (sp.length > 1) openingsSet.add(obj.name.split(":")[0]);
  }
  const op: string[] = [];
  for (const value of openingsSet) {
    op.push(value);
  }
  return op;
}

export function getOpeningName(pgn: string | undefined | null): string {
  if (!pgn) return "Unknown Opening";
  let search = pgn;
  for (let i = search.length - 1; i >= 0; i--) {
    let search = pgn.substring(0, i + 1);
    if (map.has(search)) {
      return map.get(search);
    }
  }
  return "Unknown Opening";
}

export const commonOpenings = [
  "Alekhine Defense",
  "Benko Gambit",
  "Benoni Defense",
  "Bird Opening",
  "Bishop's Opening",
  "Bogo-Indian Defense",
  "Caro-Kann Defense",
  "Catalan Opening",
  "Center Game",
  "Colle System",
  "Danish Gambit",
  "Dutch Defense",
  "English Opening",
  "Four Knights Game",
  "French Defense",
  "Grünfeld Defense",
  "Indian Game",
  "Italian Game",
  "King's Gambit",
  "King's Indian Attack",
  "King's Indian Defense",
  "King's Knight Opening",
  "King's Pawn Opening",
  "London System",
  "Nimzo-Indian Defense",
  "Nimzo-Larsen Attack",
  "Nimzowitsch Defence",
  "Old Indian Defense",
  "Owen Defence",
  "Philidor Defense",
  "Pirc Defense",
  "Polish Opening",
  "Ponziani Opening",
  "Queen's Gambit Declined",
  "Queen's Indian Defense",
  "Réti Opening",
  "Ruy Lopez",
  "Scandinavian Defense",
  "Scotch Game",
  "Semi-Slav Defense",
  "Sicilian Defense",
  "Slav Defense",
  "Tarrasch Defense",
  "Three Knights Opening",
  "Trompowsky Attack",
  "Vienna Game",
];
